import React, { useEffect, useState } from "react";
import { faArrowUpRightFromSquare, faComments, faUser } from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet";
import ItemHighlightOld from "../components/item/ItemHighlightOld";
import ImageGrid from "../components/common/ImageGrid";
import { Range } from "react-date-range";
import StarRating from "../components/common/StarRating";
import {
    Button,
    Card,
    CardBody,
    CardText,
    Col,
    Container,
    Row,
    Nav,
    NavLink,
} from "reactstrap";
import {
    BookingClient,
    BookingCreateDto,
    BookingPricingDto,
    CurrencyIsoCode,
    IConfig,
    ImageDto,
    ItemClient,
    ItemDto,
    ItemDtoPaginationDto,
    ItemPageClient,
    ItemPublicDto,
    Price,
    PricePeriod,
    RentMyUserPublicDetailedDto,
    RentMyUserPublicSimpleDto,
    UserClient,
} from "../api/rentMyApi";
import { useTranslation } from "react-i18next";
import ItemSnippet from "../components/common/ItemSnippet";
import { ItemDetailedPriceSection } from "../components/item-detailed/ItemDetailedPriceSection";
import { useLocation, useNavigate } from "react-router-dom";
import { format, isValid } from "date-fns";
import LocationCard from "../components/item-detailed/LocationCard";
import { getStandardPricePeriod } from "../components/item/ItemListingConstantsAndDefaults";
import BackButton from "../components/common/BackButton";
import {
    gotoMessagesFromItemDetailedPage,
    messageUrlConst,
} from "../components/common/MessageUrlFormatting";
import { useUserContext } from "../context/UserContext";
import { getStandardPricePeriodPriceWithServiceFeeApplied } from "../utils/pricePeriodUtils";
import { sendViewItem, viewItemAnalytics } from "../api/analytics";

import { ReviewList } from "../components/review/ReviewList";
import { NotFoundPage } from "./notFoundPage";
import { toast } from "react-toastify";

import { useAuth0 } from "@auth0/auth0-react";
import BasicModal from "../components/common/BasicModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SmallLoadingSpinner } from "../components/common/Loaders";

import he from 'he';
import { LoadingFullScreen } from "../components/common/LoadingFullScreen";
import DisplayDailyDiscounts from "../components/item-detailed/DisplayDailyDiscounts";

type InsuranceInfo = {
    status: string;
    label: string;
};

export function ItemDetailedPage() {
    const navigate = useNavigate();
    const location = useLocation();
    const params = new URLSearchParams(location.search);

    const url = location.pathname.substring(6); // removes item/

    const title = `${url.split("/")[3]}`; // assuming item name is the 4th index.

    const { t } = useTranslation();
    const { user: currentlyLoggedInUser } = useUserContext();

    const [itemDetails, setItemDetails] = useState<ItemPublicDto>(
        new ItemPublicDto()
    );
    const [isItemDetailsExist, setIsItemDetailsExist] = useState<boolean>(true);
    const [userData, setUserData] = useState<RentMyUserPublicDetailedDto>(
        new RentMyUserPublicDetailedDto()
    );
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [showReviews, setShowReviews] = useState<boolean>(false);
    const [bottomSectionActiveTab, setBottomSectionActiveTab] =
        useState("other-items");
    const [viewItemAnalyticsCalled, setViewItemAnalyticsCalled] =
        useState<boolean>(false);
    const [isMobileView, setIsMobileView] = useState<boolean>(false);
    const [isVerySmallScreenView, setIsVerySmallScreenView] =
        useState<boolean>(false);

    const [totalDailyDiscount, setTotalDailyDiscount] = useState<number>(0);

    const [standardPricePeriod, setStandardPricePeriod] = useState<PricePeriod>();
    const [images, setImages] = useState<ImageDto[]>([]);

    const [bookingPriceDetails, setBookingPriceDetails] =
        useState<BookingPricingDto>();

    const [itemClient] = useState<ItemClient>(
        new ItemClient(new IConfig("notoken"), process.env.REACT_APP_API_ENDPOINT)
    );
    const [itemPageClient] = useState<ItemPageClient>(
        new ItemPageClient(new IConfig("notoken"), process.env.REACT_APP_API_ENDPOINT)
    );
    const [userClient] = useState<UserClient>(
        new UserClient(new IConfig("notoken"), process.env.REACT_APP_API_ENDPOINT)
    );
    const [bookingClient, setBookingClient] = useState(
        new BookingClient(new IConfig("notoken"), process.env.REACT_APP_API_ENDPOINT)
    );

    const otherItemsStartingMax = 3;
    const [
        otherItemHighlightsWithoutCurrent,
        setOtherItemHighlightsWithoutCurrent,
    ] = useState<ItemDto[]>();
    const [
        otherItemHighlightsWithoutCurrentShowMaxValue,
        setOtherItemHighlightsWithoutCurrentShowMaxValue,
    ] = useState<number>(otherItemsStartingMax);

    const [totalOtherListings, setTotalOtherListings] = useState<number>();

    const [itemId, setItemId] = useState<string>("");

    const { user } = useUserContext();
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();

    const [modalInsurance, setModalInsurance] = useState(false);
    const fromInsuranceNet = itemDetails!.cheapestInsurance?.rentalPremium;

    useEffect(() => {

        if (url) {
            itemPageClient.itemUrl(url)
                .then((a) => {
                    setItemId(a.id);
                }).catch((e) => {
                });
        } else {
            console.log("One or more dependencies are not initialized");
        }


    }, [url])

    useEffect(() => {
        async function initClient() {
            try {
                const token = await getAccessTokenSilently();

                const client = new BookingClient(
                    new IConfig(token),
                    process.env.REACT_APP_API_ENDPOINT
                );
                setBookingClient(client);
            } catch (error) {
                console.log("An error occurred while initializing the booking client: ", error);
            }
        }

        initClient();
    }, [getAccessTokenSilently]);

    const startDateSearchTerm = "start-date";
    const endDateSearchTerm = "end-date";

    const [dimensions, setDimensions] = React.useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });
    const handleResize = () => {
        setDimensions({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    };
    React.useEffect(() => {
        window.addEventListener("resize", handleResize, false);
    }, []);

    const [dateRangeState, setDateRangeState] = useState<Range>({
        startDate: undefined,
        endDate: new Date(""),
        key: "selection",
    });

    useEffect(() => {
        if (!viewItemAnalyticsCalled) {
            if (!itemDetails || !itemDetails.id) {
                return;
            }
            if (isAuthenticated && user) {
                const accountID = user.id;
                const itemID = itemId;
                const searchTerm = itemDetails.name;
                const searchedLocation = itemDetails.approximateLocation?.city ?? "N/A";
                const userLocation = user.location ?? "N/A";
                sendViewItem(
                    searchTerm,
                    searchedLocation,
                    accountID,
                    itemID,
                    userLocation
                );
            }

            viewItemAnalytics({});
            setViewItemAnalyticsCalled(true);
        }
    }, [isAuthenticated, user, itemId, itemDetails]);

    useEffect(() => {
        setIsMobileView(dimensions.width < 1200);
        setIsVerySmallScreenView(dimensions.width < 445);
    }, [dimensions]);

    useEffect(() => {
        if (!itemDetails) {
            return;
        }

        if (!itemDetails?.priceInfo?.periods) {
            return;
        }

        const dailyStandardPricePeriod: PricePeriod | undefined = itemDetails.priceInfo.periods[0]

        if (
            dailyStandardPricePeriod &&
            dailyStandardPricePeriod.price &&
            dailyStandardPricePeriod.price.daily
        ) {
            setStandardPricePeriod(dailyStandardPricePeriod);
        }
    }, [itemDetails]);

    useEffect(() => {
        const startDateStr = params.get(startDateSearchTerm) || "";
        const endDateStr = params.get(endDateSearchTerm) || "";

        const startDate = new Date(startDateStr);
        const endDate = new Date(endDateStr);

        if (isValid(startDate) && isValid(endDate) && startDate < endDate) {
            setDateRangeState({
                startDate,
                endDate,
                key: "selection",
            });
        }
    }, []);

    useEffect(() => {
        async function initClient() {
            const itemDetailsResponse: ItemPublicDto | undefined =
                await getItemDetailsAsync()
                    .then((response) => {
                        if (response.id == null) {
                            setIsItemDetailsExist(false);
                            return undefined;
                        }

                        setIsItemDetailsExist(true);
                        return response;
                    })
                    .catch((error: any) => {
                        setIsItemDetailsExist(false);
                        return undefined;
                    });

            if (!itemDetailsResponse) {
                return;
            }

            if (itemDetailsResponse.description) {
                itemDetailsResponse.description = he.decode(itemDetailsResponse.description);
            }

            setItemDetails(itemDetailsResponse);
            setImages(itemDetailsResponse.images ?? []);
            populateUserOtherListings(itemDetailsResponse.user!);
            const userDetailed = await userClient.detailed52(
                itemDetailsResponse.user.id
            );
            setUserData(userDetailed);
            setIsLoading(false);
        }

        if (itemClient && userClient) {
            initClient();
        }
    }, [itemId, itemClient, userClient, location.pathname]);

    const isDateRangeValid = () => {
        return (
            dateRangeState.startDate != null &&
            dateRangeState.endDate != null &&
            dateRangeState.startDate < dateRangeState.endDate
        );
    };

    useEffect(() => {
        async function fetchBookingPrices() {
            if (dateRangeState.startDate == null || dateRangeState.endDate == null) {
                return;
            }
            const bookingCreateDto = new BookingCreateDto({
                active: true,
                itemId: itemDetails.id,
                startingDate: dateRangeState.startDate,
                endingDate: dateRangeState.endDate,
                isHourly: false,
                currency: CurrencyIsoCode.GBP,
                vouchers: undefined,
                insurancePlanId: undefined,
                referralCode: undefined,
            });
            bookingClient
                .publicPricingCheck(bookingCreateDto)
                .then((response) => {
                    setBookingPriceDetails(response);
                })
                .catch((error) => {
                    setDateRangeState({
                        startDate: undefined,
                        endDate: new Date(""),
                        key: "selection",
                    });
                    toast.error("A rental cannot be made on the selected dates.");
                });
        }

        if (bookingClient && isDateRangeValid()) {
            fetchBookingPrices();
        }
    }, [bookingClient, dateRangeState]);

    async function getItemDetailsAsync(): Promise<ItemPublicDto> {
        if (!itemId) {
            setIsItemDetailsExist(false);
            throw "id not provided so can not get item information";
        }
        return await itemClient!.detailed24(itemId!);
    }

    function populateUserOtherListings(user: RentMyUserPublicSimpleDto) {
        itemClient
            .detailed25(
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                user.id,
                undefined,
                undefined,
                5,
                1
            )
            .then((response: ItemDtoPaginationDto) => {
                if (response.dataCount == 0) {
                    return;
                }
                setTotalOtherListings(response.totalDataCount);
                setOtherItemHighlightsWithoutCurrent(
                    response.data?.filter((x: ItemDto) => x.id !== itemId)
                );
            })
            .catch((error: any) => {
                toast.error(t("item_listing_other_listing_loading_error"));
                console.error(
                    "other items listed by the user can't be retrieved: " + error.response
                );
            });
    }

    const getDailyCostWithServiceFeeApplied = (): string => {
        if (!standardPricePeriod) {
            return "";
        }

        let price: Price = standardPricePeriod.price;

        return (
            (price?.daily || 0) + (price?.dailyServiceFee || 0)).toFixed(2);
    };

    const getDailyCost = (): string => {
        if (!standardPricePeriod) {
            return "";
        }

        let price: Price = standardPricePeriod.price;

        return (
            (price?.daily || 0)).toFixed(2);
    };

    const getInsuranceInfo = (): InsuranceInfo => {
        if (itemDetails.requireInsurance) {
            return {
                status: t("item_detailed_insurance_required"),
                label: t("item_detailed_insurance"),
            };
        } else {
            if (itemDetails.externalLink) {
                return {
                    status: t("item_detailed_insurance_external"),
                    label: t("item_detailed_insurance"),
                };
            } else {
                if (itemDetails.insurancePlans && itemDetails.insurancePlans.length > 0) {
                    const isActive = itemDetails.insurancePlans.some((plan) => plan.active);
                    if (isActive) {
                        return {
                            status: t("item_detailed_insurance_optional"),
                            label: t("item_detailed_insurance"),
                        };
                    } else {
                        return {
                            status: t("item_detailed_insurance_unavailable"),
                            label: t("item_detailed_insurance"),
                        };
                    }
                } else {
                    return {
                        status: t("item_detailed_insurance_guarantee_title"),
                        label: t("item_detailed_insurance_guarantee"),
                    };
                }
            }
        }
    };

    const insuranceSection = (): JSX.Element => {
        const insuranceInfo = getInsuranceInfo();

        return (
            <>
                {fromInsuranceNet && (
                    <>
                        <div className="daily-price-detail d-flex flex-column colour-black">
                            <span className="small">
                                {t("item_detailed_insurance_price_from")}
                            </span>
                            <div style={{ paddingRight: isMobileView ? undefined : "0" }}>
                                <span className="daily-price d-flex align-items-baseline from-insurance">
                                    £{fromInsuranceNet.toFixed(2)}
                                </span>
                            </div>
                        </div>
                    </>
                )}
                <div className="insurance-label">
                    <span className="item-detailed-semi-bold">
                        {insuranceInfo.label}:
                    </span>{" "}
                    {insuranceInfo.status}
                </div>
            </>
        );
    };

    const externalPriceSection = (): JSX.Element => {
        return (
            <>
                {itemDetails.externalLink && (
                    <>
                        <div className="daily-price-detail d-flex flex-column colour-black">
                            <div>
                                <a
                                    href={itemDetails.externalLink}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    type="button"
                                    className="btn btn-primary btn-lg btn-block"
                                >
                                    {t("item_detailed_rent_me")}
                                    <span><FontAwesomeIcon style={{ paddingLeft: "5px" }}
                                        icon={faArrowUpRightFromSquare} /></span>
                                </a>
                            </div>
                        </div>
                    </>
                )}
            </>
        );
    };

    const mainSection = (): JSX.Element => {
        if (!isItemDetailsExist) {
            return (<>
                <Helmet>
                    <title>Not Found</title>
                </Helmet>

                <h1>404 Not Found</h1>
            </>)
        }
        return (
            <>
                <Card>
                    <CardBody className="item-detailed-main-section">
                        <ImageGrid
                            imageSrcs={images.map(
                                (image) => image.path!
                            )}
                        />
                        <h1 className="mt-1">{itemDetails?.name}</h1>

                        {itemDetails?.categories && itemDetails?.categories[0] && (
                            <p className="categories">{itemDetails?.categories[0].path}</p>
                        )}
                        {sharerSection()}
                        <div className="flexy">

                            <StarRating
                                overallAverage={
                                    itemDetails?.overallAverage != null
                                        ? itemDetails?.overallAverage
                                        : 0
                                }
                                reviewCount={
                                    itemDetails?.reviewCount != null
                                        ? itemDetails?.reviewCount
                                        : 0
                                }
                            />
                            {itemDetails?.approximateLocation && (
                                <p className="flex-right location">
                                    {itemDetails?.approximateLocation.city}
                                </p>
                            )}
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                            {itemDetails && (
                                <>
                                    <div className="daily-price-detail d-flex flex-column">
                                        <span className="small">
                                            {t("item_detailed_daily_price_from")}
                                        </span>
                                        <div>
                                            <span className="daily-price d-flex align-items-baseline">
                                                £{getDailyCostWithServiceFeeApplied()}
                                                <span className="price-suffix">
                                                    {t("item_detailed_per_day_alt")}
                                                </span>
                                            </span>
                                        </div>
                                    </div>


                                    {dimensions.width > 767 && insuranceSection()}
                                    {itemDetails.externalLink && externalPriceSection()}
                                    
                                    <div className="daily-price-detail d-flex flex-column">
                                        {/*
                                    <DisplayDailyDiscounts
                                            pricePeriods={itemDetails?.priceInfo.periods ?? []}
                                            dailyPrice={getDailyCost()}
                                            dateRange={dateRangeState}
                                            setDailyDiscount={setTotalDailyDiscount}
                                        />*/}
                                    </div>
                                </>
                            )}
                        </div>
                        {dimensions.width <= 767 && (
                            <div className="d-flex justify-content-between align-items-center">
                                {insuranceSection()}
                            </div>
                        )}
                    </CardBody>
                </Card>
                <BasicModal
                    modalTitle={"Rental Insurance"}
                    modalBody={
                        <div className="text-align-center">
                            {
                                "Our insurance is underwritten, ensuring you’re protected in case of any accidents or damage during your rental."
                            }
                        </div>
                    }
                    modalState={modalInsurance}
                    toggleModal={() => setModalInsurance(false)}
                    className="aviva-insurance-modal"
                />
            </>
        );
    };

    const sharerSection = () => {
        if (!userData.name) {
            return <div />;
        }

        const title = `${t("item_detailed_shared_by")} ${userData.name}`;
        const text = `${t("item_detailed_joined")} ${format(
            userData.joinedDate!,
            "MMMM yyyy"
        )}${
            userData.approximateLocation && userData.approximateLocation.city
                ? `, ${userData.approximateLocation.city}`
                : ""
        }`;

        return (
            <Row className="sharer-section">
                <div
                    className="icon-in-circle user-avatar snippet-div clickable"
                    onClick={() => navigate("/public/profile?id=" + userData.id)}
                >
                    <img
                        width="100%"
                        height="100%"
                        alt="RentMy user profile"
                        src={userData.profileImage && userData.profileImage.compressedPath
                            ? userData.profileImage.compressedPath!
                            : "assets/img/profile-pic-placeholder.webp"}
                    />
                </div>
                <h2 className="snippet-div clickable sharer-info"
                    onClick={() => navigate("/public/profile?id=" + userData.id)}>{title}</h2>
            </Row>

        );
    };

    const aspectSection = (): JSX.Element => {
        if (!itemDetails?.aspects) {
            return <></>;
        }

        const aspects: { key: string; value: string }[] = [];

        for (const key of Object.keys(itemDetails.aspects)) {
            aspects.push({ key, value: itemDetails.aspects[key][0] });
        }

        return aspects.length ? (
            <Card>
                <CardBody>
                    <h2>{t("attributes")}</h2>
                    <ul className="item-detailed-list">
                        {aspects.map((x) => {
                            return (
                                <li key={"aspect_list_" + x.key}>
                                    <span className="item-detailed-semi-bold">{x.key}:</span>{" "}
                                    {x.value}
                                </li>
                            );
                        })}
                    </ul>
                </CardBody>
            </Card>
        ) : (
            <Container />
        );
    };

    const formatBookingDays = (days: number | undefined) => {
        if (!days) return "None";
        else return days.toString() + (days === 1 ? " day" : " days");
    };

    const additionalBookingInfoSection = (): JSX.Element => {
        const insuranceInfo = getInsuranceInfo();

        return (
            <Card className="additional-info">
                <CardBody>
                    <Row className="sharer-buttons">
                        <button
                            onClick={() => handleMessageSharerClick()}
                            type="button"
                            className="item-snippet-button btn btn-outline-primary btn-sm"
                        >
                            {t("item_listing_message_sharer")}
                            <span>
                                <FontAwesomeIcon style={{ paddingLeft: "5px" }} icon={faComments} />
                            </span>
                        </button>
                        <button
                            onClick={() => {
                                navigate(`/public/profile?id=${itemDetails.user.id}`);
                            }}
                            type="button"
                            className="item-snippet-button btn btn-outline-primary btn-sm"
                        >
                            {t("item_listing_view_profile")}
                            <span>
                                <FontAwesomeIcon style={{ paddingLeft: "5px" }} icon={faUser} />
                            </span>
                        </button>
                    </Row>
                    <hr className="mt-3 mb-3" />

                    <h2>{t("item_detailed_additional_booking")}</h2>
                    <ul className="item-detailed-list">
                        <li>
                            <span className="item-detailed-semi-bold">
                                {insuranceInfo.label}:
                            </span>{" "}
                            {insuranceInfo.status}
                        </li>
                        <li>
                            <span className="item-detailed-semi-bold">
                                {t("item_detailed_min_booking")}:
                            </span>{" "}
                            {formatBookingDays(standardPricePeriod?.minimumBookingDays)}
                        </li>
                        <li>
                            <span className="item-detailed-semi-bold">
                                {t("item_detailed_max_booking")}:
                            </span>{" "}
                            {formatBookingDays(standardPricePeriod?.maximumBookingDays)}
                        </li>
                        <li>
                            <span className="item-detailed-semi-bold">
                                {t("item_detailed_cancellation_policy")}:
                            </span>{" "}
                            {itemDetails?.cancellationPolicy}
                        </li>
                    </ul>
                </CardBody>
            </Card>
        );
    };

    const handleMessageSharerClick = (): void => {
        navigate(
            `${messageUrlConst}/${gotoMessagesFromItemDetailedPage(
                itemDetails.id,
                itemDetails.name!,
                currentlyLoggedInUser.id
            )}/${userData.id}`
        );
    };

    const BottomSelectionSection = (): JSX.Element => {
        const usersFirstName = (userData.name ?? "").split(" ")[0];
        const usersTotalOtherItems = totalOtherListings ? totalOtherListings : 1 - 1;

        const shortOtherItemsTitle = `${t(
            "more_from"
        )} ${usersFirstName} (${usersTotalOtherItems})`;
        const longOtherItemsTitle = `${t(
            "item_detailed_other_user_listings"
        )} ${usersFirstName} (${usersTotalOtherItems})`;

        return (
            <Nav
                className="tab-list mt-2"
                style={{
                    paddingLeft: "16px",
                    fontSize: isVerySmallScreenView ? "16px" : "20px",
                }}
            >
                <NavLink
                    className={
                        bottomSectionActiveTab === "other-items" ? "active-tab" : ""
                    }
                    onClick={() => {
                        setBottomSectionActiveTab("other-items");
                        setShowReviews(false);
                    }}
                >
                    {isMobileView ? shortOtherItemsTitle : longOtherItemsTitle}
                </NavLink>
                <NavLink
                    className={bottomSectionActiveTab === "reviews" ? "active-tab" : ""}
                    onClick={() => {
                        setBottomSectionActiveTab("reviews");
                        setShowReviews(true);
                    }}
                >
                    {t("reviews")} ({itemDetails?.reviewCount})
                </NavLink>
            </Nav>
        );
    };

    const goToUserProfile = () => {
        navigate("/public/profile?id=" + userData.id);
    };

    const reviewsSection = (hidden: boolean) => {
        return (
            <Card className={hidden ? "hidden" : ""}>
                <CardBody>
                    {itemDetails?.reviewCount === 0 && (
                        <CardText className="center-text">{t("no_reviews")}</CardText>
                    )}
                    {
                        itemDetails?.reviewCount !== 0 && (
                            <ReviewList
                                hidden={hidden}
                                itemId={itemId}
                                userId={""}
                            ></ReviewList>
                        )
                    }
                </CardBody>
            </Card>
        );
    };

    const sharersOtherListingsSection = (): JSX.Element => {
        return (
            <div className={"other-items-container"}>
                {otherItemHighlightsWithoutCurrent &&
                    otherItemHighlightsWithoutCurrent.length === 0 && (
                        <Card>
                            <CardBody>
                                <CardText className="center-text">
                                    {t("no_other_items")}
                                </CardText>
                            </CardBody>
                        </Card>
                    )}
                <Row className="no-row-inverse-margin image-detailed-row">
                    {otherItemHighlightsWithoutCurrent &&
                        otherItemHighlightsWithoutCurrent
                            .slice(0, otherItemHighlightsWithoutCurrentShowMaxValue)
                            .map((itemHighlight: ItemDto, index: number) => {
                                return (
                                    <Col key={"item_highlight_container_" + index} xs={12} md={4}>
                                        <ItemHighlightOld
                                            item={itemHighlight}
                                            isAlreadyOnItemPage={true}
                                        />
                                    </Col>
                                );
                            })}
                    {otherItemHighlightsWithoutCurrent &&
                        otherItemHighlightsWithoutCurrent.length >
                        otherItemHighlightsWithoutCurrentShowMaxValue && (
                            <Col className="flexy" xs={12}>
                                <Button
                                    onClick={goToUserProfile}
                                    className="auto-margin btn-outline-primary"
                                >
                                    {t("see_more_listings")}
                                </Button>
                            </Col>
                        )}
                </Row>
            </div>
        );
    };

    const goBack = () => {
        navigate(-1);
    };

    const descriptionSection = (): JSX.Element => {
        return (
            <Card>
                <CardBody>
                    <h2>{t("item_detailed_description_header")}</h2>
                    <CardText>{itemDetails?.description}</CardText>
                </CardBody>
            </Card>
        );
    };

    const bookingAndLocationSection = (): JSX.Element => {
        return (
            <Row>
                <Col md={6}>
                    <div className="flex-display full-height">{additionalBookingInfoSection()}</div>
                </Col>

                <Col md={6}>
                    <div className="flex-display full-height">
                        <LocationCard
                            latitude={itemDetails?.approximateLocation?.latitude ?? 0}
                            longitude={itemDetails?.approximateLocation?.longitude ?? 0}
                            city={itemDetails?.approximateLocation?.city ?? ""}
                            country={itemDetails?.approximateLocation?.country ?? ""}
                            wantCircle={true}
                        />
                    </div>
                </Col>
            </Row>
        );
    };

    if (isLoading)
        return (
            <div className="content-layout auto-margin not-found-page">
                <Row>
                    <LoadingFullScreen />
                </Row>
            </div>
        );

    return (
        <>
            <Helmet>
                <title>{title.replaceAll("-"," ")}</title>
            </Helmet>

            {isMobileView && (
                <Container className="mt-4 mb-4 item-detail item-detailed-page-mobile-container">
                    <div className="item-detailed-header auto-margin">
                        {!isItemDetailsExist ? <NotFoundPage /> : null}
                    </div>

                    {isItemDetailsExist && (
                        <Row>
                            <Col xl={8}>
                                <div>{mainSection()}</div>
                                <div>{descriptionSection()}</div>
                                <div>{aspectSection()}</div>
                                <div>{bookingAndLocationSection()}</div>
                                {dimensions.width <= 1200 && (
                                    <ItemDetailedPriceSection
                                        itemDetails={itemDetails}
                                        dateRangeState={dateRangeState}
                                        setDateRangeState={setDateRangeState}
                                        bookingPriceDetails={bookingPriceDetails}
                                        dailyDiscountTotal={totalDailyDiscount}
                                    />
                                )}
                            </Col>
                            <Col xl={8}></Col>
                            <Col xl={8}>
                                {BottomSelectionSection()}

                                <div className="mb-4">
                                    {reviewsSection(!showReviews)}
                                    {!showReviews && sharersOtherListingsSection()}
                                </div>
                            </Col>
                        </Row>
                    )}
                </Container>
            )}
            {!isMobileView && (
                <Container className="mt-4 mb-4 item-detail">
                    {!isItemDetailsExist && (
                        <div className="item-detailed-header">
                            <NotFoundPage />
                        </div>
                    )}

                    {isItemDetailsExist && (
                        <Row>
                            <Col xl={itemDetails.externalLink ? 12 : 8}>
                                <div>{mainSection()}</div>
                                <div>{descriptionSection()}</div>
                                <div>{aspectSection()}</div>
                                <div>{bookingAndLocationSection()}</div>

                                {BottomSelectionSection()}

                                <div className="mb-4">
                                    {reviewsSection(!showReviews)}
                                    {!showReviews && sharersOtherListingsSection()}
                                </div>
                            </Col>
                            {!itemDetails.externalLink && (
                                <Col xl={4}>
                                    <div className="dates-selection fixed-date-picker">
                                        <ItemDetailedPriceSection
                                            itemDetails={itemDetails}
                                            dateRangeState={dateRangeState}
                                            setDateRangeState={setDateRangeState}
                                            bookingPriceDetails={bookingPriceDetails}
                                            externalLink={itemDetails.externalLink}
                                            dailyDiscountTotal={totalDailyDiscount}
                                        />
                                    </div>
                                </Col>
                            )}
                        </Row>
                    )}
                </Container>
            )}
        </>
    );
}