import React, {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import {
  faLocationDot,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Autocomplete from "react-google-autocomplete";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import PlaceResult = google.maps.places.PlaceResult;
import {useLocation} from "react-router-dom";

interface WhatWhereSearchBarDesktopProps {
  handleSubmit: () => Promise<void>;
  localSelectedPlace: google.maps.places.PlaceResult | undefined;
  setLocalSelectedPlace: React.Dispatch<
    React.SetStateAction<PlaceResult | undefined>
  >;
  localSearchTerm: string;
  setLocalSearchTerm: React.Dispatch<React.SetStateAction<string>>;
}


export const WhatWhereSearchBarDesktop: React.FC<WhatWhereSearchBarDesktopProps> =
  ({
    handleSubmit,
    localSelectedPlace,
    setLocalSelectedPlace,
    localSearchTerm,
    setLocalSearchTerm,
  }) => {
    const { t } = useTranslation();
    const [width, setWidth] = useState<number>(window.innerWidth);
    const [preventFormSubmit, setPreventFormSubmit] = useState(false);
    const { searchTerm } = useSelector((state: RootState) => state.search);

    const [defaultWhere, setDefaultWhere] = useState<string>("");
    const [defaultWhat, setDefaultWhat] = useState<string>("");

    const isMobile = width <= 768;
    const isMiddleScreen = width <= 1001;

    const apiKey = process.env.REACT_APP_GOOGLE_MAPS_KEY;


    const handleChangeSubmit = async (
      event: React.FormEvent<HTMLFormElement>
    ) => {
      if (preventFormSubmit) {
        event.preventDefault();
        setPreventFormSubmit(false);
        return;
      }
      event.preventDefault();

      await handleSubmit();
    };

    return (
      <form onSubmit={handleChangeSubmit} className="nav-bar-search">
        <div className="what-for-section">
          <input
            className="search-bar-text-input"
            type="text"
            placeholder={
              isMiddleScreen
                ? t("homepage_what_are_you_looking_for_short")
                : t("homepage_what_are_you_looking_for")
            }
            onChange={(e) => {
              e.preventDefault();
              setLocalSearchTerm(e.target.value);
            }}
            value={ localSearchTerm || ""}
          />
        </div>
        <div className="search-section where">
          <FontAwesomeIcon
            className="location-icon"
            icon={faLocationDot}
            color={localSelectedPlace ? "#EA554A" : "#b3bac2"}
            fontSize="20px"
          />
          <Autocomplete
            onInput={(e: any) => {
              if (e.target.value.trim() === "")
                setLocalSelectedPlace(undefined);
            }}
            aria-placeholder={
              localSelectedPlace
                ? ""
                : isMiddleScreen
                ? t("homepage_where_you_want_it_short")
                : t("homepage_where_you_want_it")
            }
            options={{
              componentRestrictions: { country: "uk" },
            }}
            onKeyDown={(event) => {
              if (event.key === "Enter" && !localSelectedPlace) {
                setPreventFormSubmit(true);
              }
            }}
            inputAutocompleteValue={localSelectedPlace?.formatted_address || ""}
            apiKey={apiKey}
            className="autocomplete"
            onPlaceSelected={(place: PlaceResult) => {
              setLocalSelectedPlace(place);
            }}
          />
        </div>
        <button className="btn nav-search-bar-btn btn-default">
          <FontAwesomeIcon icon={faMagnifyingGlass} style={{paddingTop: "4px", paddingLeft: "1px"}} />
        </button>
      </form>
    );
  };
